<template>
  <nav aria-label="Sidebar" class="sidebar__container">
    <div class="sidebar__nav--top">
      <router-link
        v-for="item in topNav"
        :key="item.name"
        :to="item.href"
        @click="refreshIfCurrent(item.href)"
        :class="[
          isCurrentRoute(item.href)
            ? 'bg-blue-500 text-white'
            : 'text-gray-200 hover:bg-gray-700',
          'sidebar__item--top',
        ]"
      >
        <span class="sr-only">{{ item.name }}</span>
        <div
          v-if="item.href == '/policies'"
          :class="[
            isCurrentRoute(item.href) ? 'text-white' : 'text-blue-200',
            'sidebar__icon--top',
          ]"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6.429 9.75L2.25 12l4.179 2.25m0-4.5l5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0l4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0l-5.571 3-5.571-3"
            />
          </svg>
        </div>
        <div
          v-else-if="item.href == '/logs'"
          :class="[
            isCurrentRoute(item.href) ? 'text-white' : 'text-blue-200',
            'sidebar__icon--top',
          ]"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9zm3.75 11.625a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
            />
          </svg>
        </div>
        <component
          v-else
          :is="item.icon"
          :class="[
            isCurrentRoute(item.href) ? 'text-white' : 'text-blue-200',
            'sidebar__icon--top',
          ]"
          aria-hidden="true"
        />
        <span class="sr-only">{{ item.name }} </span>
        <span class="pl-2 text-md">{{ item.name }}</span>
      </router-link>
    </div>

    <div class="absolute bottom-0 w-60 flex flex-col p-0 mb-6 bg-gray-800 pt-5">
      <router-link to="/system-information" class="sidebar__item--bottom__nav">
        <ServerIcon class="sidebar__icon--bottom" aria-hidden="true" />
        <span class="sr-only">System information</span>
        <span class="pl-2">System information</span>
      </router-link>
      <div class="h-1px w-11/12 self-center bg-gray-600 my-2" />
      <div class="flex flex-col p-0 space-y-1">
        <a
          v-for="item in bottomNav"
          :key="item.name"
          :href="item.href"
          target="_blank"
          class="sidebar__item--bottom"
        >
          <component
            :is="item.icon"
            class="sidebar__icon--bottom"
            aria-hidden="true"
          />
          <span class="sr-only">{{ item.name }} </span>
          <span class="pl-2">{{ item.name }}</span>
          <ExternalLinkIcon class="sidebar__icon--external" />
        </a>
      </div>
    </div>
  </nav>
</template>
<script>
import {
  CogIcon,
  UserGroupIcon,
  PuzzleIcon,
  ExternalLinkIcon,
  BookOpenIcon,
  FlagIcon,
  CheckCircleIcon,
  BanIcon,
  LightBulbIcon,
  PresentationChartLineIcon,
  XCircleIcon,
  ShieldCheckIcon,
  ClipboardListIcon,
  CubeIcon,
  KeyIcon,
} from "@heroicons/vue/outline";

import {
  QuestionMarkCircleIcon,
  ChatAltIcon,
  ServerIcon,
} from "@heroicons/vue/solid";

const topNav = [
  // { name: "Analytics", href: "/analytics", icon: PresentationChartLineIcon },
  { name: "Logs", href: "/logs", icon: LightBulbIcon },
  { name: "Denylist", href: "/denylist", icon: XCircleIcon },
  { name: "Allowlist", href: "/allowlist", icon: CheckCircleIcon },
  { name: "Rules", href: "/rules", icon: ClipboardListIcon },
  { name: "Policies", href: "/policies", icon: ShieldCheckIcon },
  { name: "Integrations", href: "/integrations", icon: CubeIcon },
  { name: "Users", href: "/users", icon: UserGroupIcon },
  { name: "Settings", href: "/settings", icon: CogIcon },
];

const bottomNav = [
  {
    name: "Knowledge Base",
    href: "https://support.preava.com/",
    icon: QuestionMarkCircleIcon,
  },
  {
    name: "Feedback",
    href: "https://www.preava.com/feedback",
    icon: ChatAltIcon,
  },
];

export default {
  methods: {
    isCurrentRoute(path) {
      return this.$route.path === path ? true : false;
    },
    refreshIfCurrent(path) {
      if (this.$route.path === path) {
        location.reload();
      }
    },
  },
  components: {
    ExternalLinkIcon,
    ServerIcon,
  },
  setup() {
    return {
      topNav,
      bottomNav,
    };
  },
};
</script>
<style scoped>
@import "./sidebar.css";
</style>
